import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Select, Store, ofActionSuccessful, Actions, ofActionDispatched } from '@ngxs/store';
import { Employee } from 'app/models/employee';
import { CompanyState, CompanyStateModel } from 'app/ngxs/company/state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SetEmployees } from 'app/ngxs/employee/actions';
import { UserState } from 'app/ngxs/user/state';
import { AppState } from 'app/ngxs/state';
import { LogoutUser } from 'app/ngxs/user/actions';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  @Select(UserState.isLoggedIn) isLoggedIn$: Observable<boolean>;
  private root: string;

  constructor(private actions$: Actions, private db: AngularFirestore, private store: Store) {
    this.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.root = this.store.selectSnapshot<string>((state: AppState) => state.company.root);
        this.watchEmployees();
      }
    });
  }

  private watchEmployees(): void {
    this.db
      .collection<Employee>(`${this.root}/employees`, (ref) => {
        return ref.orderBy('name');
      })
      .valueChanges()
      .pipe(takeUntil(this.actions$.pipe(ofActionSuccessful(LogoutUser))))
      .subscribe((employees) => {
        this.store.dispatch(new SetEmployees(employees));
      });
  }

  public getEmployeeById(id: string): Observable<Employee> {
    return this.db.doc<Employee>(`${this.root}/employees/${id}`).valueChanges();
  }
}
