import { Component } from '@angular/core';

@Component({
  selector: 'home-page',
  templateUrl: './home.html',
  styleUrls: ['./home.scss'],
})
export class HomePage {
  public features: { icon: string; content: string }[] = [
    {
      icon: 'home',
      content: 'Industry-standard and scaled drawings that are ready to print, created in an instant',
    },
    {
      icon: 'subject',
      content: 'Generates a complete list of products and measurements required to finish each job',
    },
    {
      icon: 'attach_money',
      content: 'Calculates the exact cost of material, labor, and markup. No more guessing your profit margin.',
    },
    {
      icon: 'mood',
      content: 'Showing the customer what they will be getting ahead of time means happy and confident customers',
    },
  ];
}
