import { Employee } from 'app/models/employee';

export class LoginStart {
  static readonly type = '[User] LoginStart';
}

export class LoginError {
  static readonly type = '[Auth Service] LoginError';
  constructor(public error: string) {}
}

export class LogoutUser {
  static readonly type = '[Auth Service] LogoutUser';
}

export class SetRedirectUrl {
  static readonly type = '[Auth Guard] SetRedirectUrl';
  constructor(public url: string) {}
}

export class SetUser {
  static readonly type = '[User] SetUser';
  constructor(public user: Employee) {}
}
