import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectionList, MatSnackBar } from '@angular/material';
import { Select, Store } from '@ngxs/store';
import { CycleDuration, EstimateTypePrice } from 'app/models/subscription';
import { SetCompanyRoot } from 'app/ngxs/company/actions';
import { CompanyState } from 'app/ngxs/company/state';
import { AuthService } from 'app/services/auth.service';
import { CompanyService } from 'app/services/company.service';
import { PaymentService } from 'app/services/payment.service';
import { AddressUtil } from 'app/util/address-util';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

declare var Stripe: any; // stripe.StripeStatic;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, AfterViewInit {
  @Select(CompanyState.subscriptionCosts) subscriptionCosts$: Observable<EstimateTypePrice[]>;
  @ViewChild(MatSelectionList) estimateTypesList: MatSelectionList;
  private destroy$ = new Subject();

  public companyForm: FormGroup;
  public ownerForm: FormGroup;
  public billingForm: FormGroup;
  public states = AddressUtil.states;
  public total = 0;
  public selectedEstimateTypes: EstimateTypePrice[] = [];

  @ViewChild('stripeCardNumber') stripeCardNumberEl: ElementRef;
  @ViewChild('stripeCardExpiry') stripeCardExpiryEl: ElementRef;
  @ViewChild('stripeCardCvc') stripeCardCvcEl: ElementRef;
  private stripe;
  private cardNumber;
  public cardNumberError;
  public cardExpiryError;
  public cardCvcError;

  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private functions: AngularFireFunctions,
    private paymentService: PaymentService,
    private snackBar: MatSnackBar,
    private store: Store
  ) {
    this.initForms();
  }

  ngOnInit() {
    this.estimateTypesList.selectionChange.subscribe((event) => {
      this.calculateTotal();
    });

    this.stripe = Stripe(environment.stripeApiKey);
    const elements = this.stripe.elements();

    const style = {
      base: {
        fontSize: '18px',
        fontFamily: 'Lato',
      },
    }
    this.cardNumber = elements.create('cardNumber', { style });
    this.cardNumber.mount(this.stripeCardNumberEl.nativeElement);
    this.cardNumber.addEventListener('change', ({ error }) => {
      this.cardNumberError = error && error.message;
    });

    const cardExpiry = elements.create('cardExpiry', { style });
    cardExpiry.mount(this.stripeCardExpiryEl.nativeElement);
    cardExpiry.addEventListener('change', ({ error }) => {
      this.cardExpiryError = error && error.message;
    });

    const cardCvc = elements.create('cardCvc', { style });
    cardCvc.mount(this.stripeCardCvcEl.nativeElement);
    cardCvc.addEventListener('change', ({ error }) => {
      this.cardCvcError = error && error.message;
    });
  }

  ngAfterViewInit() {
    // Select all estimate types to begin with
    this.subscriptionCosts$
      .pipe(
        filter((costs) => costs.length > 0),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          this.estimateTypesList.selectAll();
          this.calculateTotal();
        });
      });
  }

  private initForms() {
    this.companyForm = this.fb.group({
      name: ['', Validators.required],
      address: this.fb.group({
        street: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zip: ['', Validators.pattern(/\d{5}/)],
      }),
      license: ['', Validators.required],
    });

    this.ownerForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.email, Validators.required])],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

    this.billingForm = this.fb.group({
      copyCompanyAddress: [false],
      name: ['', Validators.required],
      address_line1: ['', Validators.required],
      address_city: ['', Validators.required],
      address_state: ['', Validators.required],
      address_zip: ['', Validators.required],
      address_country: [{ value: 'US', disabled: true }, Validators.required],
    });

    this.billingForm.get('copyCompanyAddress').valueChanges.subscribe((copyCompanyAddress: boolean) => {
      if (copyCompanyAddress) {
        const companyAddress = this.companyForm.value.address;
        this.billingForm.patchValue({
          address_line1: companyAddress.street,
          address_city: companyAddress.city,
          address_state: companyAddress.state,
          address_zip: companyAddress.zip,
        });
        this.billingForm.get('address_line1').disable();
        this.billingForm.get('address_city').disable();
        this.billingForm.get('address_state').disable();
        this.billingForm.get('address_zip').disable();
      } else {
        this.billingForm.patchValue({
          address_line1: '',
          address_city: '',
          address_state: '',
          address_zip: '',
        });
        this.billingForm.get('address_line1').enable();
        this.billingForm.get('address_city').enable();
        this.billingForm.get('address_state').enable();
        this.billingForm.get('address_zip').enable();
      }
    });

    // this.companyForm.patchValue({
    //   name: 'Test Company',
    //   address: {
    //     street: '123 Some St.',
    //     city: 'Nowhere',
    //     state: 'FL',
    //     zip: '12345',
    //   },
    //   license: '123ABC',
    // });

    // this.ownerForm.patchValue({
    //   firstName: 'Tester',
    //   lastName: 'Guy',
    //   email: 'tguy1@nodomain.com',
    //   password: 'testing',
    //   confirmPassword: 'testing',
    // });
  }

  private calculateTotal() {
    this.selectedEstimateTypes = this.estimateTypesList.selectedOptions.selected.map((o) => o.value);
    const totalCost = this.selectedEstimateTypes.reduce((total, cost) => total + cost.monthlyRate, 0);
    this.total = totalCost;
  }

  public async signUp() {
    let fireUser: firebase.User;
    try {
      if (this.ownerForm.invalid || this.companyForm.invalid || this.billingForm.invalid) {
        throw Error('Please fix any errors above');
      }
      if (this.selectedEstimateTypes.length === 0) {
        throw Error('You must select at least one estimate type');
      }

      // Attempt to create the stripe token first
      const billingInfo = this.billingForm.getRawValue();
      const cardOptions = {
        name: billingInfo.name,
        address_line1: billingInfo.address_line1,
        address_city: billingInfo.address_city,
        address_state: billingInfo.address_state,
        address_zip: billingInfo.address_zip,
        address_country: billingInfo.address_country,
      };
      const { token, error } = await this.stripe.createToken(this.cardNumber, cardOptions);
      if (error) {
        throw Error('You credit card information is invalid');
      }

      // Create the account
      const userInfo = this.ownerForm.value;
      fireUser = await this.authService.signUp(
        userInfo.firstName,
        userInfo.lastName,
        userInfo.email,
        userInfo.password
      );

      // Create the new company
      const companyInfo = this.companyForm.value;
      const company = await this.companyService.createCompany(
        companyInfo.name,
        companyInfo.address,
        companyInfo.license,
        CycleDuration.Monthly,
        this.selectedEstimateTypes,
        fireUser.uid,
        userInfo.firstName,
        userInfo.lastName,
        userInfo.email
      );

      // Set the company root for the next action
      await this.store.dispatch(new SetCompanyRoot(company.id)).toPromise();

      // Create the payment record. Payment amount is in pennies
      const paymentAmount = this.total * 100;
      const payment = await this.paymentService.createPayment(fireUser.uid, paymentAmount, token);
    } catch (err) {
      this.snackBar.open('There was an error during sign up', 'Close');

      // Delete the account if anything went wrong after it was created
      if (fireUser) {
        await this.authService.deleteUser(fireUser);
      }
    }
  }
}
