import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatSidenavModule,
  MatToolbarModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatBottomSheetModule,
  MatSelectModule,
  MatCheckboxModule,
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { CompanyState } from './ngxs/company/state';
import { EmployeeState } from './ngxs/employee/state';
import { UserState } from './ngxs/user/state';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomePage } from './pages/home/home';
import { LoginModalComponent } from './pages/login/login-modal.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PricingPageComponent } from './pages/pricing/pricing.component';
import { EmployeeService } from './services/employee.service';
import { EstimateTypeItemComponent } from './pages/dashboard/estimate-type-item/estimate-type-item.component';
import { EstimateTypeOptionsComponent } from './pages/dashboard/estimate-type-options/estimate-type-options.component';
import { SignupComponent } from './pages/signup/signup.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    DashboardComponent,
    LoginModalComponent,
    PageNotFoundComponent,
    PricingPageComponent,
    EstimateTypeItemComponent,
    EstimateTypeOptionsComponent,
    SignupComponent,
  ],
  entryComponents: [
    EstimateTypeOptionsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      AppRoutes,
      // { enableTracing: true } // <-- debugging purposes only
    ),
    // Angular Fire
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    // Ngxs
    NgxsModule.forRoot([UserState, CompanyState, EmployeeState]),
    // Angular Flex Layout
    FlexLayoutModule,
    // Angular Mat Components,
    MatToolbarModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  providers: [EmployeeService],
  bootstrap: [AppComponent],
})
export class AppModule {}
