import { Company } from 'app/models/company';
import { Employee } from 'app/models/employee';
import { EstimateTypePrice } from 'app/models/subscription';

export class SetCompanyRoot {
  static readonly type = '[Auth Service] SetCompanyRoot';
  constructor(public companyId: string) {}
}

export class SetCompany {
  static readonly type = '[Company Service] SetCompany';
  constructor(public company: Company) {}
}

export class SetSubscriptionCosts {
  static readonly type = '[Company Service] SetSubscriptionCosts';
  constructor(public subscriptionCosts: EstimateTypePrice[]) {}
}
