import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetCompanyRoot, SetCompany, SetSubscriptionCosts } from './actions';
import { Company } from 'app/models/company';
import { EstimateTypePrice } from 'app/models/subscription';

export interface CompanyStateModel {
  root: string;
  company: Company;
  subscriptionCosts: EstimateTypePrice[];
}

@State<CompanyStateModel>({
  name: 'company',
  defaults: {
    root: '',
    company: null,
    subscriptionCosts: [],
  },
})
export class CompanyState {
  @Selector()
  static company(state: CompanyStateModel) {
    return state.company;
  }

  @Selector()
  static subscriptionCosts(state: CompanyStateModel) {
    return state.subscriptionCosts;
  }

  @Action(SetCompanyRoot)
  setCompanyRoot(ctx: StateContext<CompanyStateModel>, action: SetCompanyRoot) {
    const root = `companies/${action.companyId}`;
    ctx.patchState({
      root: root,
    });
  }

  @Action(SetCompany)
  setCompany(ctx: StateContext<CompanyStateModel>, action: SetCompany) {
    ctx.patchState({
      company: action.company,
    });
  }

  @Action(SetSubscriptionCosts)
  setSubscriptionCosts(ctx: StateContext<CompanyStateModel>, action: SetSubscriptionCosts) {
    ctx.patchState({
      subscriptionCosts: action.subscriptionCosts,
    });
  }
}
