import { Component } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { UserState, UserStateModel } from './ngxs/user/state';
import { Observable, combineLatest } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material';
import { LoginModalComponent } from './pages/login/login-modal.component';
import { EmployeeService } from './services/employee.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @Select(UserState) userState$: Observable<UserStateModel>;
  title = 'Alumidraw';
  links: { url: string; title: string }[];
  showSignOutButton = false;
  showDashboardButton = false;
  isLoggedIn = false;

  constructor(private authService: AuthService, private employeeService: EmployeeService, private router: Router) {
    this.links = [
      { title: 'Home', url: '/home' },
      // { title: 'Pricing', url: '/pricing' },
      // { title: 'Contact', url: '/contact' },
    ];

    // this.userState$.subscribe((state) => {
    //   this.isLoggedIn = !state.isLoggingIn && state.isAuthenticated;
    //   this.setButtons();
    // });

    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.setButtons();
    //   }
    // });
  }

  private setButtons() {
    this.showSignOutButton = this.isLoggedIn && this.router.url === '/dashboard';
    this.showDashboardButton = this.router.url !== '/dashboard';
  }

  public async signOut() {
    try {
      await this.authService.signOut();
      this.router.navigateByUrl('/home');
    } catch (err) {
      console.error(err);
    }
  }
}
