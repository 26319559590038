import { Injectable } from '@angular/core';
import { ADSubscription, CycleDuration, EstimateTypeSubscription, EstimateTypePrice } from 'app/models/subscription';
import * as moment from 'moment';
import { EstimateType } from 'app/models/estimate-type';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';
import { Company, CompanySettings } from 'app/models/company';
import { UserState } from 'app/ngxs/user/state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SetCompany, SetSubscriptionCosts } from 'app/ngxs/company/actions';
import { LogoutUser } from 'app/ngxs/user/actions';
import { Employee, EmployeeRole, EmployeeStatus } from 'app/models/employee';
import { Address } from 'app/models/address';
import * as firebase from 'firebase';
import { MatSnackBar } from '@angular/material';
import { TypeScriptEmitter } from '@angular/compiler';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  @Select(UserState.isLoggedIn) isLoggedIn$: Observable<boolean>;

  get root() {
    return this.store.selectSnapshot((state) => state.company.root);
  }

  constructor(
    private actions$: Actions,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar,
    private store: Store
  ) {
    this.watchSubscriptionCosts();
    this.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.watchCompany();
      }
    });
  }

  private watchSubscriptionCosts(): void {
    this.afs
      .collection<EstimateTypePrice>(`subscription-costs`)
      .valueChanges()
      .subscribe((costs) => {
        this.store.dispatch(new SetSubscriptionCosts(costs));
      });
  }

  private watchCompany(): void {
    this.afs
      .doc<Company>(this.root)
      .valueChanges()
      .pipe(takeUntil(this.actions$.pipe(ofActionSuccessful(LogoutUser))))
      .subscribe((company) => {
        this.store.dispatch(new SetCompany(company));
      });
  }

  public async createCompany(
    companyName: string,
    companyAddress: Address,
    companyLicense: string,
    cycle: CycleDuration,
    estimateTypes: EstimateTypePrice[],
    userUid: string,
    firstName: string,
    lastName: string,
    email: string
  ): Promise<Company> {
    try {
      const company: Company = {
        id: this.afs.createId(),
        name: companyName,
        address: companyAddress,
        license: companyLicense,
        code: this.generateCompanyCode(),
        settings: this.getDefaultCompanySettings(),
        subscription: this.createSubscription(cycle, false, estimateTypes),
      };

      const owner: Employee = {
        id: userUid,
        firstName: firstName,
        lastName: lastName,
        fullName: `${firstName} ${lastName}`,
        email: email,
        role: EmployeeRole.Owner,
        deviceTokens: [],
        companies: {
          [company.id]: EmployeeStatus.Approved,
        },
      };
      const companyRef = this.afs.doc(`companies/${company.id}`).ref;
      const employeeRef = this.afs.doc(`companies/${company.id}/employees/${owner.id}`).ref;
      const userRef = this.afs.doc(`users/${owner.id}`).ref;

      const batch = this.afs.firestore.batch();
      batch.set(userRef, owner);
      batch.set(companyRef, company);
      batch.set(employeeRef, owner);
      await batch.commit();
      return company;
    } catch (err) {
      console.error(`Failed to create company: ${err}`);
      // this.logService.error('Failed to create company', err, this);
      throw err;
    }
  }

  private generateCompanyCode(length: number = 6): string {
    let code = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    for (let i = 0; i < length; i++) {
      code += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return code;
  }

  private getDefaultCompanySettings(): CompanySettings {
    return {
      gutterHangerSpacing: 3,
      gutterWedgeSpacing: 3,
      screenRoomMainWallUpright: 'beam_2x3',
    };
  }

  private createSubscription(
    cycle: CycleDuration,
    autoPay: boolean,
    estimateTypes: EstimateTypePrice[]
  ): ADSubscription {
    const startDate = moment();
    const endDate = moment(startDate).add(1, cycle === CycleDuration.Monthly ? 'month' : 'year');
    const totalCost = estimateTypes.reduce((total, type) => {
      const price = cycle === CycleDuration.Monthly ? type.monthlyRate : type.yearlyRate;
      return total + price;
    }, 0);

    const types: EstimateTypeSubscription[] = estimateTypes.map((typePricing) => {
      const basePrice = cycle === CycleDuration.Monthly ? typePricing.monthlyRate : typePricing.yearlyRate;
      return {
        type: typePricing.type,
        price: basePrice,
        active: true,
        prorated: false,
        proratedPrice: basePrice,
        cancelled: false,
        endDate: endDate.format(),
      };
    });

    return {
      startDate: startDate.format(),
      endDate: endDate.format(),
      cycleDuration: cycle,
      active: true,
      cancelled: false,
      autoPay: autoPay,
      upcomingTotal: totalCost,
      currentTotal: totalCost,
      estimateTypes: types,
    };
  }

  /**
   * Toggles the cancellation status of the provided estimate type.
   * @param subscriptionCost The estimate type to toggle
   */
  public async toggleEstimateTypeSubscription(subscriptionCost: EstimateTypePrice) {
    try {
      const companyDoc = this.afs.doc(this.root);

      await this.afs.firestore.runTransaction(async (tx) => {
        const companySnap = await tx.get(companyDoc.ref);
        const company = companySnap.data() as Company;

        if (!company.subscription.active) {
          // Company subscription has been inactive since its last term. Reactivate and reset the subscription date
          const startDate = moment();
          const endDate = moment(startDate).add(
            1,
            company.subscription.cycleDuration === CycleDuration.Monthly ? 'month' : 'year'
          );
          company.subscription.startDate = startDate.format();
          company.subscription.endDate = endDate.format();
          company.subscription.active = true;
        }

        const basePrice =
          company.subscription.cycleDuration === CycleDuration.Monthly
            ? subscriptionCost.monthlyRate
            : subscriptionCost.yearlyRate;
        let typeSubscription = company.subscription.estimateTypes.find((t) => t.type === subscriptionCost.type);
        if (!typeSubscription) {
          // Doesn't exist. Create a prorated placeholder
          typeSubscription = {
            type: subscriptionCost.type,
            price: 0,
            active: false,
            cancelled: false,
            prorated: false,
            proratedPrice: 0,
            endDate: company.subscription.endDate,
          };
          company.subscription.estimateTypes.push(typeSubscription);
        }

        if (typeSubscription.active) {
          // Currently active. Toggle cancellation
          typeSubscription.cancelled = !typeSubscription.cancelled;
        } else {
          // Currently inactive
          typeSubscription.active = true;
          typeSubscription.cancelled = false;
          typeSubscription.price = basePrice;

          if (company.subscription.active) {
            // Company is still active. Prorate the price
            typeSubscription.prorated = true;
            typeSubscription.proratedPrice = this.calculateProratedPrice(company.subscription, subscriptionCost);
          } else {
            // Company is not active. Payments are starting from a fresh billing cycle
            typeSubscription.proratedPrice = typeSubscription.price;
          }
        }

        // Mark the entire subscription cancelled if all estimate types are cancelled
        const areAllCancelled = company.subscription.estimateTypes.every((t) => t.cancelled || !t.active);
        company.subscription.cancelled = areAllCancelled;

        // Recalculate the total subscription cost for the upcoming month
        company.subscription.upcomingTotal = company.subscription.estimateTypes.reduce((total, t) => {
          return t.active && !t.cancelled ? total + t.price : total;
        }, 0);
        company.subscription.currentTotal = company.subscription.estimateTypes.reduce((total, t) => {
          return total + t.proratedPrice || 0;
        }, 0);

        tx.update(companyDoc.ref, {
          subscription: company.subscription,
        });
      });

      // Show toast here about autopay
    } catch (err) {
      console.error(`Failed to add estimate type to subscription: ${err}`);
    }
  }

  private calculateProratedPrice(subscription: ADSubscription, subscriptionCost: EstimateTypePrice): number {
    const basePrice =
      subscription.cycleDuration === CycleDuration.Monthly ? subscriptionCost.monthlyRate : subscriptionCost.yearlyRate;
    const daysInTerm = moment(subscription.endDate).diff(moment(subscription.startDate), 'days');
    const daysRemainingInTerm = moment(subscription.endDate).diff(moment(), 'days') + 1; // Include today
    const pricePerDay = basePrice / daysInTerm;
    const proratedPrice = pricePerDay * daysRemainingInTerm;
    return proratedPrice;
  }

  /**
   * Cancels a company's subscription to all estimate types
   */
  public async cancelSubscription() {
    try {
      const companyDoc = this.afs.doc(this.root);

      await this.afs.firestore.runTransaction(async (tx) => {
        const companySnap = await tx.get(companyDoc.ref);
        const company = companySnap.data() as Company;

        company.subscription.autoPay = false;
        company.subscription.cancelled = true;
        company.subscription.estimateTypes.forEach((type) => {
          type.cancelled = true;
        });

        tx.update(companyDoc.ref, {
          subscription: company.subscription,
        });
      });

      // Show toast here about autopay
    } catch (err) {
      console.error(`Failed to toggle autopay: ${err}`);
    }
  }

  public async toggleAutopay() {
    try {
      const companyDoc = this.afs.doc(this.root);

      let autoPay: boolean;
      await this.afs.firestore.runTransaction(async (tx) => {
        const companySnap = await tx.get(companyDoc.ref);
        const company = companySnap.data() as Company;

        autoPay = company.subscription ? !company.subscription.autoPay : true;

        tx.update(companyDoc.ref, {
          'subscription.autoPay': autoPay,
        });
      });

      this.snackBar.open(`Successfully turned auto pay ${autoPay ? 'on' : 'off'}`, 'Close', {
        duration: 3000,
      });
    } catch (err) {
      console.error(`Failed to toggle autopay: ${err}`);
    }
  }
}
