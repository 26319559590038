import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { CompanyService } from 'app/services/company.service';
import { Company } from 'app/models/company';
import { EstimateTypeSubscription, EstimateTypePrice } from 'app/models/subscription';

@Component({
  selector: 'app-estimate-type-options',
  templateUrl: './estimate-type-options.component.html',
  styleUrls: ['./estimate-type-options.component.scss'],
})
export class EstimateTypeOptionsComponent implements OnInit {
  public company: Company;
  public subscriptionCost: EstimateTypePrice;
  public typeSubscription: EstimateTypeSubscription;
  public actions: { description: string; handler: () => void }[] = [];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public bottomSheetRef: MatBottomSheetRef<EstimateTypeOptionsComponent>,
    private companyService: CompanyService
  ) {
    this.company = data.company;
    this.subscriptionCost = data.subscriptionCost;
    this.typeSubscription = data.typeSubscription;
  }

  ngOnInit() {
    this.initActions();
  }

  private initActions() {
    let subscriptionToggleText: string;
    if (!this.typeSubscription.active) {
      subscriptionToggleText = 'Add to subscription';
    } else {
      // Active
      if (!this.typeSubscription.cancelled) {
        subscriptionToggleText = `Cancel ${this.subscriptionCost.type} subscription`;
      } else {
        subscriptionToggleText = 'Undo cancellation';
      }
    }

    this.actions.push({
      description: subscriptionToggleText,
      handler: () => this.toggleTypeSubscription(),
    });
  }

  private async toggleTypeSubscription() {
    try {
      await this.companyService.toggleEstimateTypeSubscription(this.subscriptionCost);
      this.bottomSheetRef.dismiss();
    } catch (err) {}
  }
}
