import { EstimateType } from './estimate-type';
import { FirestoreDocument } from './firestore-document';

/**
 * Subscriptions can be paid for on a monthly or yearly basis where the subscription starts upon purchase
 * - Monthly subscriptions should occur on the same day every month
 * - Yearly subscriptions shoud occur on that same date every year
 *
 * Auto pay should be an option
 *
 * Companies should be able to add on an estimate type at any time, which means prorating must be supported
 * based on their initial subscription start date
 * 
 * Companies can switch from/to monthly and yearly at any time
 */

export enum CycleDuration {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export interface Bill {
  /** The start date of the billing cycle */
  startDate: string;
  /** The end date of the billing cycle */
  endDate: string;
  /** The total cost for this billing cycle */
  total: number;
  /** The individual items that make up the total cost for this bill */
  lineItems: BillLineItem[];
}

export enum BillLineItemType {
  EstimateType = 'Estimate Type',
  Employee = 'Employee',
}

export interface BillLineItem {
  type: BillLineItemType;
  /** The corresponding id of the line item (Employees) */
  id?: string;
  /** The friendly text for the line item. ie Screen Room Estimates, Employee: James Smith */
  description: string;
  /** The cost of the individual item. ie  */
  cost: number;
}

export interface EstimateTypePrice extends FirestoreDocument {
  /** The estimate type */
  type: EstimateType;
  /** The monthly cost of this estimate type */
  monthlyRate: number;
  /** The annual cost of this estimate type */
  yearlyRate: number;
}

export interface EstimateTypeSubscription {
  type: EstimateType;
  /** The full-term price of this estimate type */
  price: number;
  /** True if this estimate type can actively be used within the app */
  active: boolean;
  /** True if this estimate type was added in during the billing cycle */
  prorated: boolean;
  /** The prorated price that is based on the full-term price */
  proratedPrice: number;
  /**
   * True if this estimate type was removed from the subscription.
   * The 'active' field will still be true until the subscription's
   * end date has passed
   */
  cancelled: boolean;
  /** The end date of this estimate. This is a duplicate of the subscription object's endDate */
  endDate: string;
}

export interface ADSubscription {
  startDate: string;
  endDate: string;
  active: boolean;
  cancelled: boolean;
  cycleDuration: CycleDuration;
  autoPay: boolean;
  /** The total of the next terms bill. This total does NOT account for prorated subscriptions */
  upcomingTotal: number;
  /** The total charges during the current term. This total accounts for prorated subscriptions */
  currentTotal: number;
  estimateTypes: EstimateTypeSubscription[];
}
