import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { EstimateTypeSubscription, EstimateTypePrice, CycleDuration } from 'app/models/subscription';
import * as moment from 'moment';
import { Select } from '@ngxs/store';
import { CompanyState } from 'app/ngxs/company/state';
import { Observable, Subject } from 'rxjs';
import { Company } from 'app/models/company';
import { takeUntil, skipWhile } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material';
import { EstimateTypeOptionsComponent } from '../estimate-type-options/estimate-type-options.component';

@Component({
  selector: 'estimate-type-item',
  templateUrl: './estimate-type-item.component.html',
  styleUrls: ['./estimate-type-item.component.scss'],
})
export class EstimateTypeItemComponent implements OnInit, OnDestroy {
  @Select(CompanyState.company) company$: Observable<Company>;
  @Input() subscriptionCost: EstimateTypePrice;
  private destroy$ = new Subject();

  public company: Company;
  public typeSubscription: EstimateTypeSubscription;
  public status: string;
  public color: string;

  constructor(private bottomSheet: MatBottomSheet) {}

  ngOnInit() {
    this.company$
      .pipe(
        skipWhile((c) => !c),
        takeUntil(this.destroy$)
      )
      .subscribe((company) => {
        this.company = company;
        this.typeSubscription = company.subscription.estimateTypes.find((t) => t.type === this.subscriptionCost.type);

        if (!this.typeSubscription) {
          // The company does not have this subscription. Create an inactive placeholder.
          this.typeSubscription = {
            type: this.subscriptionCost.type,
            price:
              company.subscription.cycleDuration === CycleDuration.Monthly
                ? this.subscriptionCost.monthlyRate
                : this.subscriptionCost.yearlyRate,
            proratedPrice: 0,
            active: false,
            cancelled: false,
            endDate: null,
            prorated: false,
          };
        }

        let statusStr = '';
        if (this.typeSubscription.active) {
          statusStr += 'Active';
          this.color = 'green';

          if (this.typeSubscription.cancelled) {
            const endDate = moment(this.typeSubscription.endDate).format('MMM D, YYYY');
            statusStr += ` until ${endDate}`;
            this.color = 'red';
          }
        } else {
          statusStr += 'Inactive';
          this.color = 'gray';
        }
        this.status = statusStr;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  public openOptions() {
    this.bottomSheet.open(EstimateTypeOptionsComponent, {
      data: {
        company: this.company,
        subscriptionCost: this.subscriptionCost,
        typeSubscription: this.typeSubscription,
      },
    });
  }
}
