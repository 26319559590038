import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngxs/store';
import { Employee, EmployeeStatus, EmployeeRole } from 'app/models/employee';
import { SetCompanyRoot } from 'app/ngxs/company/actions';
import { LoginStart, LogoutUser, SetUser, LoginError } from 'app/ngxs/user/actions';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth, private store: Store) {
    this.initAuth();
  }

  public async initAuth() {
    const fireUser = await this.afAuth.authState.pipe(first()).toPromise();
    if (fireUser) {
      // Get root user
      const userSnap = await this.afs.doc<Employee>(`users/${fireUser.uid}`).ref.get();
      const user = userSnap.data() as Employee;
      const companyId = Object.keys(user.companies).find((cId) => user.companies[cId] === EmployeeStatus.Approved);
      this.store.dispatch([new SetCompanyRoot(companyId), new SetUser(user)]);
    } else {
      this.store.dispatch(new SetUser(null));
    }
  }

  public async signIn(email: string, password: string): Promise<void> {
    try {
      this.store.dispatch(new LoginStart());
      // await timer(2000).toPromise();
      // const isLoggedIn = this.store.selectSnapshot((state) => state.user.isAuthenticated);
      // if (isLoggedIn) {
      //   await this.signOut();
      // }

      const credential = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
      const firebaseUser = credential.user;

      // Fetch the user doc to verify the user's approval status
      const userSnapshot = await this.afs.doc(`users/${firebaseUser.uid}`).ref.get();
      const user = userSnapshot.data() as Employee;

      const companyIds = Object.keys(user.companies);
      if (companyIds.length >= 1) {
        // User belongs to a single company
        const companyId: string = companyIds[0];
        const isAdmin = user.role === EmployeeRole.Owner || user.role === EmployeeRole.Admin;

        if (!isAdmin) {
          // Only owners and admins have access to the web portal
          throw Error('Only company admins have access to the dashboard');
        }

        const approvalStatus: EmployeeStatus = user.companies[companyId];
        if (approvalStatus === EmployeeStatus.Rejected) {
          // User was rejected
          throw Error('Your account was rejected. Please contact your company admin for more information.');
        } else if (approvalStatus === EmployeeStatus.Pending) {
          // User is still pending approval
          throw Error('Your account is still pending approval.');
        }

        // User is approved and is already logged in
        this.store.dispatch(new SetCompanyRoot(companyId));
      }

      // Mark the login as complete
      await this.store.dispatch(new SetUser(user)).toPromise();
    } catch (err) {
      await this.afAuth.auth.signOut();

      let errorMessage: string;
      if (err.code === 'auth/invalid-email') {
        errorMessage = 'The email you entered is in an invalid format';
      } else if (err.code === 'auth/user-disabled') {
        errorMessage = 'This account is disabled';
      } else if (err.code === 'auth/user-not-found') {
        errorMessage = 'This email address was not found';
      } else if (err.code === 'auth/wrong-password') {
        errorMessage = 'The password is incorrect';
      } else if (err.code === 'auth/network-request-failed') {
        errorMessage = 'Connection failed. Please ensure you are connected to the internet when logging in.';
      } else {
        errorMessage = err.message || JSON.stringify(err);
      }
      this.store.dispatch(new LoginError(errorMessage));
      throw Error(errorMessage);
    }
  }

  public async signOut(): Promise<void> {
    this.store.dispatch(new LogoutUser());
    await this.afAuth.auth.signOut();
  }

  public async signUp(firstName: string, lastName: string, email: string, password: string): Promise<firebase.User> {
    try {
      const emailCredential = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);
      const fireUser = emailCredential.user;
      const fullName = `${firstName} ${lastName}`;
      await this.updateDisplayName(fireUser, fullName);
      return fireUser;
    } catch (err) {
      let errorMessage: string;
      if (err.code === 'auth/invalid-email') {
        errorMessage = 'The email you entered is in an invalid format';
      } else if (err.code === 'auth/email-already-in-use') {
        errorMessage = 'There is already an account associated with that email';
      } else if (err.code === 'auth/weak-password') {
        errorMessage = 'The password you entered is too weak';
      } else if (err.code === 'auth/operation-not-allowed') {
        errorMessage = 'Email/password accounts are disabled';
      } else if (err.code === 'auth/network-request-failed') {
        errorMessage = 'Connection failed. Please ensure you are connected to the internet before signing up.';
      } else {
        errorMessage = err.message;
      }

      throw Error(errorMessage);
    }
  }

  public async deleteUser(fireUser: firebase.User) {
    try {
      await fireUser.delete();
    } catch (err) {
      console.error('Failed to delete user: ' + err);
    }
  }

  public async updateDisplayName(user: firebase.User, displayName: string): Promise<void> {
    await user.updateProfile({
      displayName: displayName,
      photoURL: '',
    });
  }
}
