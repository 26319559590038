import { FirestoreDocument, FirestoreMap } from './firestore-document';

export enum EmployeeStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected'
}

export enum EmployeeRole {
    Owner = 'Owner',
    Admin = 'Admin',
    Salesman = 'Salesman',
    Installer = 'Installer'
}

export interface Employee extends FirestoreDocument {
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    role?: EmployeeRole;
    deviceTokens?: string[];
    companies?: FirestoreMap<EmployeeStatus>;
}
