import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetUser, LoginStart, LogoutUser, LoginError, SetRedirectUrl } from './actions';
import { Employee } from 'app/models/employee';
import { EmployeeService } from 'app/services/employee.service';

export interface UserStateModel {
  isLoggingIn: boolean;
  isAuthenticated: boolean;
  error: string;
  user: Employee;
  redirectUrl: string;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    isLoggingIn: true,
    isAuthenticated: false,
    error: null,
    user: null,
    redirectUrl: null,
  },
})
export class UserState {
  @Selector()
  static isLoggedIn(state: UserStateModel) {
    return state.isAuthenticated;
  }

  @Action(LoginStart)
  loginStart(ctx: StateContext<UserStateModel>) {
    ctx.patchState({
      isLoggingIn: true,
      error: null,
    });
  }

  @Action(LoginError)
  loginError(ctx: StateContext<UserStateModel>, action: LoginError) {
    ctx.patchState({
      isLoggingIn: false,
      error: action.error,
    });
  }

  @Action(LogoutUser)
  logoutStart(ctx: StateContext<UserStateModel>) {
    ctx.patchState({
      isAuthenticated: false,
      user: null,
    });
  }

  @Action(SetRedirectUrl)
  setRedirectUrl(ctx: StateContext<UserStateModel>, action: SetRedirectUrl) {
    ctx.patchState({
      redirectUrl: action.url,
    });
  }

  @Action(SetUser)
  setUser(ctx: StateContext<UserStateModel>, action: SetUser) {
    ctx.patchState({
      user: action.user,
      isAuthenticated: !!action.user,
      isLoggingIn: false,
    });
  }
}
