import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Employee, EmployeeStatus, EmployeeRole } from 'app/models/employee';
import { EstimateType } from 'app/models/estimate-type';
import { ADSubscription, EstimateTypeSubscription, CycleDuration, EstimateTypePrice } from 'app/models/subscription';
import { EmployeeState, EmployeeStateModel } from 'app/ngxs/employee/state';
import { EmployeeService } from 'app/services/employee.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CompanyService } from 'app/services/company.service';
import { CompanyState, CompanyStateModel } from 'app/ngxs/company/state';
import { Company } from 'app/models/company';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @Select(CompanyState) companyState$: Observable<CompanyStateModel>;
  @Select(EmployeeState) employeeState$: Observable<EmployeeStateModel>;

  public company: Company;
  public subscriptionCosts: EstimateTypePrice[];
  public subscription: ADSubscription;
  public subscribedEstimateTypes: EstimateTypeSubscription[];

  constructor(private companyService: CompanyService, private employeeService: EmployeeService) {}

  ngOnInit() {
    this.companyState$.subscribe((state) => {
      this.company = state.company;
      this.subscriptionCosts = state.subscriptionCosts;
    });
  }

  toggleAutopay() {
    this.companyService.toggleAutopay();
  }
}
