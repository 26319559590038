// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  stripeApiKey: 'pk_test_k3rEXo2FgyqvR7c65cfVBFKI00t4fEkLHE',
  firebaseConfig: {
    apiKey: 'AIzaSyCCdCu6uKSsxuE5VQudoAvTlolF246jarU',
    authDomain: 'alumidraw-dev.firebaseapp.com',
    databaseURL: 'https://alumidraw-dev.firebaseio.com',
    projectId: 'alumidraw-dev',
    storageBucket: 'alumidraw-dev.appspot.com',
    messagingSenderId: '636951422625',
  },
};
