import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { UserState, UserStateModel } from 'app/ngxs/user/state';
import { AuthService } from 'app/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  @Select(UserState) userState$: Observable<UserStateModel>;
  @Input() modalId: string;
  loginForm: FormGroup;

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }

  constructor(
    private dialogRef: MatDialogRef<LoginModalComponent>,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private store: Store
  ) {
    this.buildForm();
  }

  ngOnInit() {}

  private buildForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  public async signIn() {
    if (this.store.selectSnapshot((state) => state.user.isLoggingIn)) {
      console.log('user is already logging in');
      return;
    }

    try {
      const loginInfo = this.loginForm.value;
      await this.authService.signIn(loginInfo.email.trim(), loginInfo.password);
      this.router.navigateByUrl('dashboard');
      this.dialogRef.close();
    } catch (err) {
      this.snackBar.open(err.message, 'Close', {
        duration: 3000,
      });
      console.error(err);
    }
  }

  // public close() {
  //   const modal = UIkit.modal('#login-modal');
  //   modal.hide();
  // }
}
