import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Select, Store } from '@ngxs/store';
import { Payment } from 'app/models/payment';
import { UserState } from 'app/ngxs/user/state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  @Select(UserState.isLoggedIn) isLoggedIn$: Observable<boolean>;

  get root() {
    return this.store.selectSnapshot((state) => state.company.root);
  }

  constructor(private afs: AngularFirestore, private store: Store) {}

  public async createPayment(userId: string, amount: number, stripeToken: any): Promise<Payment> {
    try {
      const payment: Payment = {
        id: this.afs.createId(),
        userId: userId,
        amount: amount,
        token: stripeToken,
        charge: null,
        failed: false,
      };
      await this.afs.doc(`${this.root}/payments/${payment.id}`).set(payment);
      return payment;
    } catch (err) {
      console.error(`Failed to save stripe payment: ${err}`);
      throw err;
    }
  }
}
