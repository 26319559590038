import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetEmployees } from './actions';
import { Employee } from 'app/models/employee';

export interface EmployeeStateModel {
  employeesLoading: boolean;
  employees: Employee[];
}

@State<EmployeeStateModel>({
  name: 'employee',
  defaults: {
    employeesLoading: true,
    employees: [],
  },
})
export class EmployeeState {
  @Selector()
  static employees(state: EmployeeStateModel) {
    return state.employees;
  }

  @Action(SetEmployees)
  setEmployees(ctx: StateContext<EmployeeStateModel>, action: SetEmployees) {
    ctx.patchState({
      employees: action.employees,
      employeesLoading: false,
    });
  }
}
