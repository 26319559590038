import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth-guard/auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomePage } from './pages/home/home';
import { LoginModalComponent } from './pages/login/login-modal.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PricingPageComponent } from './pages/pricing/pricing.component';
import { SignupComponent } from './pages/signup/signup.component';

export const AppRoutes: Routes = [
  { path: 'home', component: HomePage },
  // { path: 'pricing', component: PricingPageComponent },
  // { path: 'login', component: LoginModalComponent },
  // { path: 'signup', component: SignupComponent },
  // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];
